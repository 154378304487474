.scenarioTreeContainer {
  border-style: none solid solid solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.1);
}

.scenarioTree {
  position: relative;
  color: #141414;
  height: 400px;
  min-height: 67px;
  padding-left: 12px;
  outline: none;
}

.scenarioTree div {
  outline: none;
}

.scenarioTree .noData {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 1rem;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
}

.footer {
  height: 37px;
  padding: 5px;
  color: #141414;
  font-size: 0.9rem;
  border-top: solid 1px rgba(0, 0, 0, 0.1);

  label {
    font-weight: normal;
    margin-left: 20px;
  }
}

.row {
  padding-right: 20px;
}

.rowLabel {
  margin-left: 2px;
  margin-right: 8px;
}

.renameTextBox {
  padding: 0px;
  height: 24px;
}

$icon-size: 20px;

.icon {
  position: relative;
  font-family: 'Font Awesome 5 Free';
  font-weight: normal;
  font-style: normal;
}

.iconDomain:before {
  @extend .icon;
  content: '\f07c';
  font-weight: 400;
  color: #ffbe00;
}

.iconScenario:before {
  @extend .icon;
  content: '\f15c';
  font-weight: 400;
  color: #333;
  margin-left: 2px;
  margin-right: 4px;
}

.iconDraftScenario:before {
  @extend .icon;
  content: '\f15c';
  font-weight: 400;
  color: #d1d1d1;
  margin-left: 2px;
  margin-right: 4px;
}

.iconScenarioWithoutAction:before {
  @extend .icon;
  content: '\f15c';
  font-weight: 400;
  color: #df6363;
  margin-left: 2px;
  margin-right: 4px;
}

.iconComplete:before {
  @extend .icon;
  content: '\f00c';
  font-weight: 900;
  color: #77ad41;
  left: 4px;
  top: 1px;
}

.iconEdit:before {
  @extend .icon;
  content: '\f044';
  font-weight: 900;
  color: #1d5c8b;
  left: 4px;
  top: 1px;
}

.iconRemove:before {
  @extend .icon;
  content: '\f00d';
  font-weight: 900;
  color: #df6363;
  left: 6px;
  top: 1px;
}

.iconDisabledAddDomain:before {
  @extend .icon;
  content: '\f65e';
  font-weight: 900;
  color: #8babc9;
  cursor: not-allowed;
  left: 4px;
  top: 1px;
}

.iconAddDomain:before {
  @extend .icon;
  content: '\f65e';
  font-weight: 900;
  color: #1d5c8b;
  left: 4px;
  top: 1px;
}

.iconAddScenario:before {
  @extend .icon;
  content: '\f477';
  font-weight: 900;
  color: #1d5c8b;
  left: 6px;
  top: 1px;
}
