.node {
  min-width: 100%;
  position: relative;
}

.node {
  min-width: 100%;
  white-space: nowrap;
  position: relative;
  text-align: left;
}

.node.rtl {
  text-align: right;
}

.nodeContent {
  position: absolute;
  top: 0;
  bottom: 0;
}

/* ==========================================================================
   Scaffold

    Line-overlaid blocks used for showing the tree structure
   ========================================================================== */
.lineBlock,
.absoluteLineBlock {
  height: 100%;
  position: relative;
  display: inline-block;
}

.absoluteLineBlock {
  position: absolute;
  top: 0;
}

.lineHalfHorizontalRight::before,
.lineFullVertical::after,
.lineHalfVerticalTop::after,
.lineHalfVerticalBottom::after {
  position: absolute;
  content: '';
  background-color: black;
}

/**
 * +-----+
 * |     |
 * |  +--+
 * |     |
 * +-----+
 */
.lineHalfHorizontalRight::before {
  height: 1px;
  top: 50%;
  right: 0;
  width: 50%;
}

.rtl.lineHalfHorizontalRight::before {
  left: 0;
  right: initial;
}

/**
 * +--+--+
 * |  |  |
 * |  |  |
 * |  |  |
 * +--+--+
 */
.lineFullVertical::after,
.lineHalfVerticalTop::after,
.lineHalfVerticalBottom::after {
  width: 1px;
  left: 50%;
  top: 0;
  height: 100%;
}

/**
 * +--+--+
 * |  |  |
 * |  |  |
 * |  |  |
 * +--+--+
 */
.rtl.lineFullVertical::after,
.rtl.lineHalfVerticalTop::after,
.rtl.lineHalfVerticalBottom::after {
  right: 50%;
  left: initial;
}

/**
 * +-----+
 * |  |  |
 * |  +  |
 * |     |
 * +-----+
 */
.lineHalfVerticalTop::after {
  height: 50%;
}

/**
 * +-----+
 * |     |
 * |  +  |
 * |  |  |
 * +-----+
 */
.lineHalfVerticalBottom::after {
  top: auto;
  bottom: 0;
  height: 50%;
}

/* Highlight line for pointing to dragged row destination
   ========================================================================== */
/**
 * +--+--+
 * |  |  |
 * |  |  |
 * |  |  |
 * +--+--+
 */
.highlightLineVertical {
  z-index: 3;
}
.highlightLineVertical::before {
  position: absolute;
  content: '';
  background-color: #36c2f6;
  width: 8px;
  margin-left: -4px;
  left: 50%;
  top: 0;
  height: 100%;
}

.rtl.highlightLineVertical::before {
  margin-left: initial;
  margin-right: -4px;
  left: initial;
  right: 50%;
}

@keyframes arrow-pulse {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  30% {
    transform: translate(0, 300%);
    opacity: 1;
  }
  70% {
    transform: translate(0, 700%);
    opacity: 1;
  }
  100% {
    transform: translate(0, 1000%);
    opacity: 0;
  }
}
.highlightLineVertical::after {
  content: '';
  position: absolute;
  height: 0;
  margin-left: -4px;
  left: 50%;
  top: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid white;
  animation: arrow-pulse 1s infinite linear both;
}

.rtl.highlightLineVertical::after {
  margin-left: initial;
  margin-right: -4px;
  right: 50%;
  left: initial;
}

/**
 * +-----+
 * |     |
 * |  +--+
 * |  |  |
 * +--+--+
 */
.highlightTopLeftCorner::before {
  z-index: 3;
  content: '';
  position: absolute;
  border-top: solid 8px #36c2f6;
  border-left: solid 8px #36c2f6;
  box-sizing: border-box;
  height: calc(50% + 4px);
  top: 50%;
  margin-top: -4px;
  right: 0;
  width: calc(50% + 4px);
}

.rtl.highlightTopLeftCorner::before {
  border-right: solid 8px #36c2f6;
  border-left: none;
  left: 0;
  right: initial;
}

/**
 * +--+--+
 * |  |  |
 * |  |  |
 * |  +->|
 * +-----+
 */
.highlightBottomLeftCorner {
  z-index: 3;
}
.highlightBottomLeftCorner::before {
  content: '';
  position: absolute;
  border-bottom: solid 8px #36c2f6;
  border-left: solid 8px #36c2f6;
  box-sizing: border-box;
  height: calc(50% + 4px);
  top: 0;
  right: 0;
  width: calc(50% + 4px);
}

.rtl.highlightBottomLeftCorner::before {
  border-right: solid 8px #36c2f6;
  border-left: none;
  left: 12px;
  right: initial;
}

.highlightBottomLeftCorner::after {
  content: '';
  position: absolute;
  height: 0;
  right: -12px;
  top: 100%;
  margin-top: -24px;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 12px solid #36c2f6;
}

.rtl.highlightBottomLeftCorner::after {
  left: 0;
  right: initial;
  border-right: 12px solid #36c2f6;
  border-left: none;
}
